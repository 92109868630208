import Alpine from 'alpinejs'


// Call Alpine.
window.Alpine = Alpine;



// Ready state
(function($){ 
    $( document.body ).on( 'added_to_cart', function(){
        var event = new CustomEvent("triggerCartUpdate", {});
        document.dispatchEvent(event);
    });
})(jQuery); // "jQuery" Working with WP (added the $ alias as argument)

document.addEventListener('alpine:init', () => {
    Alpine.data('headerCart', () => ({
        init: function() {
            this.updateCart()
            document.addEventListener("triggerCartUpdate", (e) =>  {
                this.updateCart()
            });
        },
        updateCart: function() {
            var data = {
                'action': 'cart_count_retriever'
            };
            jQuery.post('/wp-admin/admin-ajax.php', data, (response) => {
                console.log('Cart item',    response)
                this.count = response;
            });
        },
        count: 0,
    }))
})

